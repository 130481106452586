import { Box } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { getNPCCollected } from "../../api/npc";
import { MyNavbar } from "../../components/common/navbar/MyNavbar";
import { CardGridNPC } from "../../components/list_homebrew/CardGridNpc";
import { NPCDataConverter } from "../../components/list_homebrew/DataConverters";
import { COLLECTIONS } from "../../constants/General";
import { useApiClient } from "../../context/ApiClient";
import { useAuthState } from "../../context/AuthState";

export default function Creations(): ReactElement {
  const authState = useAuthState();
  const apiClient = useApiClient();

  return (
    <Box backgroundColor="npc.background" minH="100vh">
      <MyNavbar />
      <CardGridNPC
        title="Saved NPCs BETA"
        filterList={[]}
        apiMethod={(filterValues, paginationOptions) => getNPCCollected(apiClient.getClient(), authState.id!, paginationOptions)}
        dataConverter={NPCDataConverter}
        contentType="personal"
        collection={COLLECTIONS.NPCs}
      />
    </Box>
  );
}
